import React, { useEffect, useState } from 'react';
import { Modal, Container, Row, Col, Card } from 'react-bootstrap';
import Layout from '../../../layout/Layout';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import MobileCampaignReportHeader from '../../../images/mobilereport/MOBILE_CAMPAIGN_HEADER-01.png';
import TotalImpressionsIcon from '../../../images/mobilereport/MOBILE_EMAIL_ICONS_TOTAL_IMPRESSIONS.png';
import TotalClicksIcon from '../../../images/mobilereport/MOBILE_EMAIL_ICONS_TOTAL_CLICKS.png';
import ClickThroughRateIcon from '../../../images/mobilereport/MOBILE_EMAIL_ICONS_CLICK_THROUGH_RATE_CTR.png';
import '../../../App.css';
import '../../../App.css';

// Preload images
const preloadImage = (url) => {
  const link = document.createElement('link');
  link.rel = 'preload';
  link.href = url;
  link.as = 'image';
  document.head.appendChild(link);
};

  
const MobileCampaignReport = () => {
  const { initialLoginData } = useSelector((state) => state.user);
  const agreements = initialLoginData?.Agreements || [];
  const [selectedContract, setSelectedContract] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const userName = initialLoginData?.Account?.ContactName?.split(' ')[0];
  // Group contracts by month-ending date
  const groupedContracts = agreements
    .flatMap((agreement) =>
      agreement.Contracts.filter((contract) => contract.MobileUrl?.Url)
    )
    .reduce((acc, contract) => {
      const monthEnding = contract.MobileCampaignUpdateMonthEnding || 'N/A';
      if (!acc[monthEnding]) acc[monthEnding] = [];
      acc[monthEnding].push(contract);
      return acc;
    }, {});

  const handleOpenModal = (contract) => {
    setSelectedContract(contract);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedContract(null);
  };

  useEffect(() => {
    const imagesToPreload = [
      MobileCampaignReportHeader,
      TotalImpressionsIcon,
      TotalClicksIcon,
      ClickThroughRateIcon,
    ];
    imagesToPreload.forEach(preloadImage);
  }, []);

  return (
    <Layout>
      <h3 className="text-center pt-4 mb-4 fw-bold">Mobile Campaign Reports</h3>
      <Container className="my-3 g-0">
        {Object.keys(groupedContracts).map((monthEnding, index) => (
          <Card
            className="m-3 col-lg-8 col-md-10 col-sm-12 mx-auto shadow-sm border-0"
            key={index}
            style={{
              borderRadius: '12px',
              overflow: 'hidden',
            }}
          >
            <Card.Header
              className="d-flex justify-content-between align-items-center"
              style={{
                background: 'linear-gradient(135deg, #026ac6, #39aae2)',
                color: 'white',
                fontWeight: '600',
              }}
            >
              <div>Month Ending - {new Date(monthEnding).toLocaleDateString('en-US', { year: 'numeric', month: 'long' })}</div>
              <FontAwesomeIcon
className="text-white"
icon={faCircleInfo}
              />
            </Card.Header>
            <Card.Body
              className="p-3"
              style={{
                backgroundColor: '#f8f9fa',
              }}
            >
              {groupedContracts[monthEnding].map((contract, idx) => (
                <div
                  className="mb-3 p-2 bg-white rounded shadow-sm"
                  key={idx}
                  onClick={() => handleOpenModal(contract)}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.transform = 'translateY(-3px)';
                    e.currentTarget.style.boxShadow = '0 8px 15px rgba(0, 0, 0, 0.1)';
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.transform = 'translateY(0)';
                    e.currentTarget.style.boxShadow = 'none';
                  }}
                  style={{
                    cursor: 'pointer',
                    transition: 'transform 0.2s, box-shadow 0.2s',
                  }}
                >
                  <div className="row align-items-center">
                    <div className="col-12">
                      <strong className="text-dark">{contract.Venue}</strong>
                    </div>
                    <div className="col-12 text-start text-secondary">
                      <small>{contract.ContractNumber}</small>
                    </div>
                  </div>
                </div>
              ))}
            </Card.Body>
          </Card>
        ))}
      </Container>
      {/* Modal Section */}
      <Modal
        centered
        onHide={handleCloseModal}
        show={modalOpen}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5">Mobile Campaign Report</Modal.Title>
        </Modal.Header>
        {selectedContract && (
          <Modal.Body>
            {/* Header Section */}
            <img
              alt="Header"
              className="metrics-header img-fluid mb-3"
              src={MobileCampaignReportHeader}
            />

            {/* Introduction */}
            <div className="mb-3 px-3">
                <p>Hello {userName},</p>
                <p>
                  Thank you for choosing GymTV to extend your ad campaign beyond the gym with an interactive mobile
                  advertisement. Your mobile marketing campaign with GymTV gets your message in front of the right
                  audience, whether they're in the gym, at home, or on-the-go.
                </p>
                <p>Here's an update on how your campaign is performing:</p>
              </div>

            {/* Metrics Section */}
            <Container>
              <Row className="justify-content-center text-center g-3">
                <Col
                  md={4}
                  xs={12}
                >
                  <div>
                    <img
                      alt="Total Impressions Icon"
                      className="metrics-icon img-fluid mb-2"
                      src={TotalImpressionsIcon}
                      style={{ maxWidth: '80px' }}
                    />
                    <h5>{selectedContract.MobileImpressions || '0'}</h5>
                    <p className="metrics-title fw-bold">Total Impressions</p>
                    <p className="metrics-description">
                      Performance Impressions shows the number of ads delivered for each day of the campaign.
                    </p>
                  </div>
                </Col>
                <Col
                  md={4}
                  xs={12}
                >
                  <div>
                    <img
                      alt="Total Clicks Icon"
                      className="metrics-icon img-fluid mb-2"
                      src={TotalClicksIcon}
                      style={{ maxWidth: '80px' }}
                    />
                    <h5>{selectedContract.MobileClicks || '0'}</h5>
                    <p className="metrics-title fw-bold">Total Clicks</p>
                    <p className="metrics-description">
                      Performance Clicks shows the number of times the ad was clicked on that day.
                    </p>
                  </div>
                </Col>
                <Col
                  md={4}
                  xs={12}
                >
                  <div>
                    <img
                      alt="Click Through Rate Icon"
                      className="metrics-icon img-fluid mb-2"
                      src={ClickThroughRateIcon}
                      style={{ maxWidth: '80px' }}
                    />
                      <h5>
                        {selectedContract.MobileImpressions
                          ? ((selectedContract.MobileClicks / selectedContract.MobileImpressions) * 100).toFixed(1) + '%'
                          : '0%'}
                      </h5>


                    <p className="metrics-title fw-bold">Click Through Rate (CTR)</p>
                    <p className="metrics-description">
                      Performance CTR shows the click-through rate for each given day (clicks / impressions).
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>

            {/* Additional Details */}
            <div className="px-3 mt-3">
              <p>
                <strong>Venue Name:</strong> {selectedContract.Venue || 'N/A'}
              </p>
              <p>
                <strong>Mobile Total Impressions Sold:</strong> {selectedContract.MobileImpressionsSold || 'N/A'}
              </p>
              <p>
                <strong>Mobile Campaign Update at Month Ending:</strong>{' '}
                {(() => {
                  const currentDate = new Date();
                  const campaignDate = new Date(selectedContract.MobileCampaignUpdateMonthEnding);

                  // Check if the date is valid and within the last 10 years
                  return campaignDate && !isNaN(campaignDate) && (currentDate - campaignDate) / (1000 * 60 * 60 * 24 * 365.25) <= 10
                    ? campaignDate.toLocaleDateString('en-US') // Format the date (MM/DD/YYYY)
                    : 'N/A';
                })()}

              </p>
            </div>
          </Modal.Body>
        )}
      </Modal>
    </Layout >
  );
};

export default MobileCampaignReport;
