// Path: /path/to/your/redux/slices/signageSlice.js

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { updateAllChannels } from './entertainmentSlice';

export const uploadImage = createAsyncThunk(
  'digitalSignage/UploadImage',
  async ({ formData, orderID, xmlContent }, { rejectWithValue }) => {
    const token = localStorage.getItem('token');

    try {
      if (formData) {
        const uploadResponse = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/DigitalSignage/UploadImage`,
          formData,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          }
        );

        const data = uploadResponse.data;

        if (data) {
          let arrayXmlContent;

          if (Array.isArray(xmlContent)) {
            arrayXmlContent = xmlContent;
          } else {
            arrayXmlContent = [xmlContent];
          }
          arrayXmlContent = arrayXmlContent.map(item => {
            if (item.ContentID === 'contentID_placeholder') {
              return { ...item, ContentID: data };
            }
            return item;
          });

          try {
            const saveMarketingOrderContentPayload = {
              OrderID: orderID,
              XmlContent: arrayXmlContent
            };

            await axios.post(
              `${process.env.REACT_APP_BASE_URL}/api/DigitalSignage/SaveMarketingOrderContent`,
              saveMarketingOrderContentPayload,
              {
                headers: {
                  'Authorization': `Bearer ${token}`,
                  'Content-Type': 'application/json',
                },
              }
            );
          } catch (innerError) {
            console.error(`Failed to save marketing order content: ${innerError}`);
            return rejectWithValue(innerError.response.data);
          }
        } else {
          console.log(`Upload failed with code: ${data.ret}`);
        }
      }
    } catch (error) {
      console.log('Server Response:', error.response);
      return rejectWithValue(error.response.data);
    }
  }
);

export const uploadVideo = createAsyncThunk(
  'digitalSignage/UploadVideo',
  async ({ formData, orderID, xmlContent }, { rejectWithValue }) => {
    const token = localStorage.getItem('token');

    try {
      if (formData) {
        const uploadResponse = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/DigitalSignage/UploadVideo`,
          formData,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          }
        );

        const data = uploadResponse.data;

        if (data) {
          let arrayXmlContent;

          if (Array.isArray(xmlContent)) {
            arrayXmlContent = xmlContent;
          } else {
            arrayXmlContent = [xmlContent];
          }
          arrayXmlContent = arrayXmlContent.map(item => {
            if (item.ContentID === 'contentID_placeholder') {
              return { ...item, ContentID: data };
            }
            return item;
          });

          try {
            const saveMarketingOrderContentPayload = {
              OrderID: orderID,
              XmlContent: arrayXmlContent
            };

            await axios.post(
              `${process.env.REACT_APP_BASE_URL}/api/DigitalSignage/SaveMarketingOrderContent`,
              saveMarketingOrderContentPayload,
              {
                headers: {
                  'Authorization': `Bearer ${token}`,
                  'Content-Type': 'application/json',
                },
              }
            );
          } catch (innerError) {
            console.error(`Failed to save marketing order content: ${innerError}`);
            return rejectWithValue(innerError.response.data);
          }
        } else {
          console.log(`Upload failed with code: ${data.ret}`);
        }
      }
    } catch (error) {
      console.log('Server Response:', error.response);
      return rejectWithValue(error.response.data);
    }
  }
);

export const editContent = createAsyncThunk(
  'digitalSignage/EditImageDate',
  async ({ contentID, newDate, orderID, xmlContent }, { rejectWithValue }) => {
    const token = localStorage.getItem('token');
    console.log('edit');
    try {
      let arrayXmlContent;

      if (Array.isArray(xmlContent)) {
        arrayXmlContent = xmlContent;
      } else {
        arrayXmlContent = [xmlContent];
      }

      arrayXmlContent = arrayXmlContent.map(item => {
        if (item.ContentID === contentID) {
          return { ...item, Date: newDate };
        }
        return item;
      });

      const saveMarketingOrderContentPayload = {
        OrderID: orderID,
        XmlContent: arrayXmlContent
      };

      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/DigitalSignage/SaveMarketingOrderContent`,
        saveMarketingOrderContentPayload,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
    } catch (error) {
      console.log('Server Response:', error.response);
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchMarketingOrderContent = createAsyncThunk(
  'digitalSignage/fetchMarketingOrderContent',
  async (arg, { getState, rejectWithValue }) => {
    const token = localStorage.getItem('token');
    const { user } = getState();
    const { selectedVenue } = user;
    try {
      let content;
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/DigitalSignage/getMarketingOrderContent?venueId=${selectedVenue.EntityID}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const digitalSignageSlice = createSlice({
  name: 'digitalSignage',
  initialState: {
    status: 'idle',
    error: null,
    marketingOrderContent: null,
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(uploadImage.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(uploadImage.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(uploadImage.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(editContent.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(editContent.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(editContent.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchMarketingOrderContent.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchMarketingOrderContent.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.marketingOrderContent = action.payload;
      })
      .addCase(fetchMarketingOrderContent.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(uploadVideo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(uploadVideo.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(uploadVideo.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  }
});

export default digitalSignageSlice.reducer;
