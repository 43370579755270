import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMusic, faImages, faMessage, faUser, faHeadset, faChartLine } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PlusIcon from './PlusIcon';
import './mobile-nav.scss';

const TapBar = () => {
  const user = useSelector((state) => state.user);
  const adAccount = user.initialLoginData?.Account;
  const roles = user.user?.RoleIds || [];
  const location = useLocation();
  const navigate = useNavigate();
  const validPaths = ['/digital-signage', '/scrolling-text'];
  const showReportsIcon = user.initialLoginData?.Agreements?.[0]?.Contracts.some((contract) => {
    return contract.MobileUrl !== null;
  });


  return (
    <div className="tapbar mobile wrapper">
      <div className="navbar d-flex justify-content-between align-items-center">
        {roles.includes(6) && (
          <div
            className="icon-wrapper text-center"
            onClick={() => navigate('/entertainment')}
          >
            <FontAwesomeIcon
              className="content-overview"
              icon={faMusic}
            />
            <div className="icon-caption">Entertainment</div>
          </div>
        )}
        {roles.includes(1) && (
          <div
            className="icon-wrapper text-center"
            onClick={() => navigate('/digital-signage')}
          >
            <FontAwesomeIcon
              className="account-settings"
              icon={faImages}
            />
            <div className="icon-caption">Digital Signage</div>
          </div>
        )}
        {roles.includes(7) && (
          <div
            className="icon-wrapper text-center"
            onClick={() => navigate('/scrolling-text')}
          >
            <FontAwesomeIcon
              className="fa-icon account-settings"
              icon={faMessage}
            />
            <div className="icon-caption">Scrolling Text</div>
          </div>
        )}
        {adAccount && (
          <>
            <div
              className="icon-wrapper text-center"
              onClick={() => navigate('/ad-account')}
            >
              <FontAwesomeIcon
                className="icon-settings"
                icon={faUser}
              />
              <div className="icon-caption">Account</div>
            </div>
            {showReportsIcon && (
              <div
                className="icon-wrapper text-center"
                onClick={() => navigate('/reports')}
              >
                <FontAwesomeIcon
                  className="icon-settings"
                  icon={faChartLine}
                />
                <div className="icon-caption">Reports</div>
              </div>
            )}

          </>
        )}
        {adAccount ? (
          <div className="icon-wrapper text-center">
            <FontAwesomeIcon
              className="icon-settings"
              icon={faHeadset}
              onClick={() => {
                window.location.href = 'https://chat.socialintents.com/c/mygymtv';
              }}
            />
            <div className="icon-caption">Support</div>
          </div>
        ) : (
          <div className="icon-wrapper text-center">
            <FontAwesomeIcon
              className="icon-settings"
              icon={faHeadset}
              onClick={() => {
                window.location.href = 'https://chat.socialintents.com/c/clubcom';
              }}
            />
            <div className="icon-caption">Support</div>
          </div>
        )}

        {validPaths.includes(location.pathname) && (
          <>
            <PlusIcon />
            <div className="circleBackground" />
          </>
        )}
      </div>
    </div>
  );
};

export default TapBar;
