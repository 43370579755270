import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchProofStatuses } from '../../../redux/slices/adPortal/proofSlice';
import { Card, ButtonGroup, Button, Form, Modal } from 'react-bootstrap';
import axios from 'axios';
import '../order-tracker.scss';
import StepTracker from './StepTracker';
import { determineTypeFromUrl } from '../../../helpers/proofHandler';
import { useOrderStatus } from '../../../hooks/useOrderStatus';

const ProofReady = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orderStatus } = useOrderStatus();
  const [activeRevisionProof, setActiveRevisionProof] = useState(null);
  const { user, initialLoginData } = useSelector((state) => state.user);
  const { statuses } = useSelector((state) => state.proofStatus);
  const advertiserCode = initialLoginData?.Account.AdvertiserCode;
  const agreements = initialLoginData?.Agreements;
  const { agreementID } = useParams();
  const [adsByStatus, setAdsByStatus] = useState({
    Waiting: [],
    Approved: [],
    Rejected: [],
  });
  const [lastApprovedRequestData, setLastApprovedRequestData] = useState(null);
  const [remainingProofs, setRemainingProofs] = useState(adsByStatus.Waiting.length);
  const [showRemainingProofsModal, setShowRemainingProofsModal] = useState(false);
  const [proofs, setProofs] = useState([]);
  const [notes, setNotes] = useState('');
  const [modalContent, setModalContent] = useState({});
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const isHtmlContent = (url) => url.includes('.html');
  const isVideoContent = (url) => url.includes('.mp4');

  const ensureHttps = (url) => {
    if (url.startsWith('http:')) {
      return url.replace('http:', 'https:');
    }
    return url;
  };

  useEffect(() => {
    agreementID && dispatch(fetchProofStatuses(agreementID));
  }, [agreementID]);

  useEffect(() => {
    const addProofs = [];
    const urlsSet = new Set();

    agreements?.forEach(agreement => {
      agreement.Contracts?.forEach(contract => {
        const types = [
          { key: 'LocalMockupUrlNS', defaultType: 'Local Mockup NS' },
          { key: 'LocalMockupUrlSS', defaultType: 'Local Mockup SS' },
          { key: 'SyndicatedUrl', defaultType: 'Syndicated Ad' },
          { key: 'MobileUrl', defaultType: 'Mobile Ad' }
        ];
        types.forEach(({ key, defaultType }) => {
          const urlObject = contract[key];
          if (urlObject?.Url && !urlsSet.has(urlObject.Url)) {
            const type = determineTypeFromUrl(urlObject.Url, defaultType);
            addProofs.push({ type, url: ensureHttps(urlObject.Url), contractNumber: contract.ContractNumber, lastUpdated: urlObject.TimeStamp });
            urlsSet.add(urlObject.Url);
          }
        });
      });
    });

    setProofs(addProofs);
  }, [agreements]);


  useEffect(() => {
    const prioritizeStatus = (statuses) => {
      const priority = { Approved: 3, Rejected: 2, Waiting: 1 };
      const deduplicated = statuses.reduce((acc, status) => {
        if (!acc[status.Url] || priority[status.Status] > priority[acc[status.Url].Status]) {
          acc[status.Url] = status;
        }
        return acc;
      }, {});
      return Object.values(deduplicated);
    };

    const uniqueStatuses = prioritizeStatus(statuses);

    let updatedAdsByStatus = { ...adsByStatus, Waiting: [] };

    proofs?.forEach((proof) => {
      const proofUrl = proof.url;
      let isCategorized = false;

      uniqueStatuses.forEach((status) => {
        if (proofUrl === status.Url) {
          const statusCategory = status.Status;
          if (['Approved', 'Rejected', 'Waiting'].includes(statusCategory)) {
            if (!updatedAdsByStatus[statusCategory].some(ad => ad.url === proof.url)) {
              updatedAdsByStatus[statusCategory].push({
                ...proof,
                status: statusCategory,
                notes: status.Notes || '',
              });
            }
            isCategorized = true;
          }
        }
      });

      if (!isCategorized) {
        updatedAdsByStatus['Waiting'].push(proof);
      }
    });

    setAdsByStatus(updatedAdsByStatus);
  }, [proofs, statuses]);



  useEffect(() => {
    setRemainingProofs(adsByStatus.Waiting.length);
  }, [adsByStatus]);


  const getFileNameWithQueryParams = (url) => {
    try {
      const parsedUrl = new URL(url);
      const pathname = parsedUrl.pathname;
      let fileName = pathname.split('/').pop();
      let queryParams = parsedUrl.search.slice(1).split('&').map(param => {
        return param.replace(/=/g, '-').replace(/\?/g, '_');
      }).join('_');

      if (queryParams) {
        fileName += `_${queryParams}`;
      }

      return fileName;
    } catch (error) {
      console.error('Error parsing URL:', error);
      return 'default_filename';
    }
  }


  const handleProofApproval = async ({ notes, url, contractNumber, lastUpdated }) => {
    const token = localStorage.getItem('token');
    const fileName = getFileNameWithQueryParams(url);

    const requestData = {
      directory: '',
      file: fileName,
      notes: notes,
      status: 'Approved',
      advertiserCode: advertiserCode,
      contractNumber: contractNumber,
      url: url,
      fileName: `${fileName}.png`,
      lastUpdated: lastUpdated,
      userID: user.UserID,
      agreementID: agreementID
    };

    try {
      await axios.post(`${process.env.REACT_APP_BASE_URL}/api/AdPortalUpload/ApproveProof`, requestData, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setLastApprovedRequestData(requestData);

      dispatch(fetchProofStatuses(agreementID));
    } catch (error) {
      console.error('Error in handleProofApproval:', error);
    }

    const remaining = remainingProofs - 1;
    setRemainingProofs(remaining);

    setModalContent({
      title: 'Proof Approved',
      message: 'Your proof has been successfully approved!',
      remainingMessage: remaining && remaining > 0 ? `You have ${remaining} ${remaining === 1 ? 'proof' : 'proofs'} left to approve or revise.` : null
    });

    setShowSuccessModal(true);
  };

  useEffect(() => {
    const prioritizeStatus = (statuses) => {
      const priority = { Approved: 3, Rejected: 2, Waiting: 1 };
      const deduplicated = statuses.reduce((acc, status) => {
        if (!acc[status.Url] || priority[status.Status] > priority[acc[status.Url].Status]) {
          acc[status.Url] = status;
        }
        return acc;
      }, {});
      return Object.values(deduplicated);
    };

    const uniqueStatuses = prioritizeStatus(statuses);
    const allApproved = uniqueStatuses.every(status => status.Status === 'Approved');

    const statusID = orderStatus === 10 ? 20 : 14;
    if (allApproved && lastApprovedRequestData) {
      submitAdTrackerUpdate(lastApprovedRequestData, statusID)
        .then(() => {
          setLastApprovedRequestData(null);
        })
        .catch(console.error);
    }
  }, [statuses, lastApprovedRequestData]);


  const handleInputChange = (event) => {
    setNotes(event.target.value);
  };

  const handleRevisionRequestToggle = (proofUrl = null) => {
    setActiveRevisionProof(proofUrl);
    setNotes('');
  };

  const handleRevisionRequest = async ({ url, contractNumber, lastUpdated }) => {
    const statusID = orderStatus === 10 ? 17 : 11;
    const token = localStorage.getItem('token');
    if (url) {
      const fileName = getFileNameWithQueryParams(url);

      const requestData = {
        directory: `\\\\sweb5\\ClubCom\\Websites\\AdPortalContent\\${advertiserCode}\\Proofs\\Rejected`,
        file: fileName,
        notes: notes,
        status: 0,
        advertiserCode: advertiserCode,
        contractNumber: contractNumber,
        url: url,
        fileName: `${fileName}.png`,
        lastUpdated: lastUpdated,
        userID: user.UserID,
        agreementID: agreementID
      };

      try {
        await axios.post(`${process.env.REACT_APP_BASE_URL}/api/AdPortalUpload/RevisionRequest`, requestData, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        await submitAdTrackerUpdate(requestData, statusID);
        await dispatch(fetchProofStatuses(agreementID));
      } catch (error) {
        console.error('Error in handleRevisionRequest:', error);
      }
    } else { console.log('oops') }
    // Update remaining proofs
    const remaining = remainingProofs - 1;
    setRemainingProofs(remaining);

    // Set the modal content for revision request
    setModalContent({
      title: 'Revision Requested',
      message: 'Your revision request has been successfully submitted!',
      remainingMessage: remaining && remaining > 0 ? `You have ${remaining} ${remaining === 1 ? 'proof' : 'proofs'} left to approve or revise.` : null
    });

    // Show the modal
    setShowSuccessModal(true);

  };


  const submitAdTrackerUpdate = async (jsonString, typeID) => {
    const data = JSON.stringify(jsonString);
    const token = localStorage.getItem('token');
    const workQueueItemData = {
      TypeID: typeID,
      AgreementID: parseInt(agreementID),
      UserID: user.UserID,
      Data: data
    };

    try {
      await axios.post(`${process.env.REACT_APP_BASE_URL}/api/CRM/SubmitAdTrackerUpdate`, workQueueItemData, {
        headers: {
          Authorization: `Bearer ${token}`
        },
      });
      setNotes('');
      setActiveRevisionProof(false);
      console.log('Ad Tracker Update submitted successfully');
    } catch (error) {
      console.error('Error submitting Ad Tracker Update:', error);
    }
  };

  const typeCounters = {};
  const renderAds = (ads, status) => {
    return (
      <>
        {ads.length > 0 && (
          <Card.Header className="proof-ready-header text-end">
            {status.includes('Waiting') ? `${status} for Approval` : status}
          </Card.Header>
        )}
        {ads.map((ad, index) => {
          const isRevisionFormOpen = activeRevisionProof === ad.url;
          return (
            <Card
              className="border-1 p-1 my-3 mx-1"
              key={ad.url}
            >
              <Card.Body className="p-1">
                <div className={`${status}-tag d-flex justify-content-between align-items-center w-100`}>
                  {`${ad.type} ${typeCounters[ad.type] >= 1 ? typeCounters[ad.type] : ''}`}
                </div>
                <div>{status.Notes}</div>
                {isHtmlContent(ad.url) ? (
                  <iframe
                    className="text-center p-2"
                    src={ad.url}
                    title={ad.type}
                  />
                ) : isVideoContent(ad.url) ? (
                  <video
                    className="w-75 text-center p-4"
                    controls
                    src={ad.url}
                  >
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img
                    alt="Ad Content"
                    className="w-100 p-2"
                    src={ad.url}
                  />

                )}
                {ad.notes && <div>Rejected reason: <i>{ad.notes}</i></div>}
                {!status.includes('Rejected') && !status.includes('Approved') && (
                  <>
                    <ButtonGroup className="w-100">
                      <Button onClick={() => handleProofApproval(ad)}>Approve Changes</Button>
                      <Button onClick={() => handleRevisionRequestToggle(ad.url)}>Request Revision</Button>
                    </ButtonGroup>
                    {isRevisionFormOpen && (
                      <Form className="mt-3">
                        <Form.Group controlId="revisionNotes">
                          <Form.Label>Notes:</Form.Label>
                          <Form.Control
                            as="textarea"
                            className="w-100 rounded-1"
                            onChange={(e) => setNotes(e.target.value)}
                            rows={3}
                            value={notes}
                          />
                        </Form.Group>
                        <ButtonGroup className="w-100 mt-2">
                          <Button
                            onClick={(e) => { e.preventDefault(); handleRevisionRequest({ notes, url: ad.url, contractNumber: ad.contractNumber, lastUpdated: ad.lastUpdated }); }}
                            type="submit"
                            variant="primary"
                          >Submit Revision Request</Button>
                          <Button
                            onClick={() => handleRevisionRequestToggle()}
                            variant="secondary"
                          >Cancel</Button>
                        </ButtonGroup>
                      </Form>
                    )}
                  </>
                )}
              </Card.Body>
            </Card>
          );
        })}
      </>
    );
  };
  return (
    <>
      <div className="status-bg">
        <StepTracker />
        <Card className="border-0 status-description">
          <Card.Body className="pt-0">

            <>
              <Card.Title
                className="step-title text-center d-flex justify-content-center align-items-center"
              >
                Proofs Ready
              </Card.Title>
              <Card.Text>
                Your ads are now online for approval!
              </Card.Text>
            </>


            <Modal
              onHide={() => setShowSuccessModal(false)}
              show={showSuccessModal}
            >
              <Modal.Header closeButton>
                <Modal.Title>{modalContent.title}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>{modalContent.message}</p>
                {modalContent.remainingMessage && <p>{modalContent.remainingMessage}</p>}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => setShowSuccessModal(false)}
                  variant="secondary"
                >
                  Close
                </Button>
                {remainingProofs === 0 && (
                  <Button
                    onClick={() => navigate('/ad-account')}
                    variant="primary"
                  >
                    Exit
                  </Button>
                )}
              </Modal.Footer>
            </Modal>

          </Card.Body>
        </Card>
      </div>
      {renderAds(adsByStatus.Waiting, 'Waiting')}
      {renderAds(adsByStatus.Approved, 'Approved')}
      {renderAds(adsByStatus.Rejected, 'Rejected')}
    </>
  );
};

export default ProofReady;
