import React from 'react';
import Card from 'react-bootstrap/Card';
import '../order-tracker.scss';
import StepTracker from './StepTracker';

const Approval = ({ activeKey, handleSetActiveKey }) => {

  return (
    <div className="status-bg">
      <StepTracker
        activeKey={activeKey}
        handleSetActiveKey={handleSetActiveKey}
      />
      <Card className="border-0 status-description">
        <Card.Body className="pt-0">
          <Card.Title
            className="step-title text-center d-flex justify-content-center align-items-center"
          >
            Approval
          </Card.Title>
          <Card.Text>
            Your ad is now in the queue to be scheduled by our traffic team.
          </Card.Text>

        </Card.Body>
      </Card>
    </div>

  );
};

export default Approval;
