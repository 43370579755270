import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../../../../layout/Layout';
import { Button, Form, Card, Modal, Spinner } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import {
    uploadImage,
    uploadVideo,
    editContent
} from '../../../../redux/slices/myGymTV/signageSlice';

const UploadSignage = () => {
    const navigate = useNavigate();
    const { id: paramContentID } = useParams();
    const dispatch = useDispatch();

    // Redux state
    const user = useSelector((state) => state.user) || [];
    const signage = useSelector((state) => state.signage.marketingOrderContent) || [];

    // If signages exist, find existing content
    const content = signage?.find((c) => c.ContentID === parseInt(paramContentID));

    // Local state
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [title, setTitle] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [height, setHeight] = useState(null);
    const [width, setWidth] = useState(null);
    const [videoDuration, setVideoDuration] = useState(null);
    const [contentTypeID, setContentTypeID] = useState(null);
    const [displaySeconds, setDisplaySeconds] = useState(null);
    const [mediaType, setMediaType] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [editSignage, setEditSignage] = useState([]);

    // Initialize edit mode fields if there's existing content
    useEffect(() => {
        if (content) {
            setTitle(content.ContentName);
            setStartDate(content.StartDate ? new Date(content.StartDate) : null);
            setEndDate(content.EndDate ? new Date(content.EndDate) : null);
            setEditSignage([
                {
                    contentID: content.ContentID,
                    title: content.ContentName,
                    startDate: new Date(content.StartDate),
                    endDate: new Date(content.EndDate)
                }
            ]);

            if (content.ContentType === 'I') {
                setMediaType(1);
                setContentTypeID(3);
                setDisplaySeconds(15);
            } else if (content.ContentType === 'V') {
                setMediaType(2);
                setContentTypeID(2);
                setDisplaySeconds(content.DisplaySeconds);
            }
        }
    }, [content]);

    function toSafeInteger(num) {
        if (Number.isSafeInteger(num)) {
            return num;
        }
        return Math.min(
            Math.max(Math.round(num), Number.MIN_SAFE_INTEGER),
            Number.MAX_SAFE_INTEGER
        );
    }

    // Validate and preview file
    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        const allowedMediaTypes = [
            'image/png',
            'image/jpeg',
            'image/jpg',
            'image/gif',
            'video/mp4',
            'video/webm',
            'video/ogg',
            'video/mpeg',
            'video/quicktime'
        ];

        if (!allowedMediaTypes.includes(file.type)) {
            alert(
                'Please upload a valid image or video format: PNG, JPG, JPEG, GIF, MP4, WebM, Ogg, MPEG, MOV.'
            );
            event.target.value = '';
            return;
        }

        setSelectedFile(file);
        const reader = new FileReader();
        reader.onload = () => {
            const isVideo = file.type.startsWith('video');

            if (!isVideo) {
                setMediaType(1);
                setDisplaySeconds(15);
                setContentTypeID(3);
                setPreviewImage(reader.result);
                const image = new Image();
                image.onload = () => {
                    setHeight(image.height);
                    setWidth(image.width);
                };
                image.src = reader.result;
            } else {
                setMediaType(2);
                setContentTypeID(2);

                const video = document.createElement('video');
                video.preload = 'metadata';
                video.onloadedmetadata = () => {
                    const durationInSeconds = toSafeInteger(video.duration);
                    if (durationInSeconds > 60) {
                        alert('Video duration cannot be longer than 1 minute.');
                        // Reset form
                        setSelectedFile(null);
                        setVideoDuration(null);
                        setContentTypeID(null);
                        setDisplaySeconds(null);
                        setMediaType(null);
                        setPreviewImage(null);
                        setHeight(null);
                        setWidth(null);
                        event.target.value = '';
                        return;
                    }
                    setDisplaySeconds(durationInSeconds);
                    video.currentTime = durationInSeconds / 3;
                };
                video.onseeked = () => {
                    const canvas = document.createElement('canvas');
                    canvas.height = video.videoHeight;
                    canvas.width = video.videoWidth;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
                    const thumbnail = canvas.toDataURL('image/jpeg');
                    setPreviewImage(thumbnail);
                    setHeight(canvas.height);
                    setWidth(canvas.width);
                };
                video.src = URL.createObjectURL(file);
            }
        };
        reader.readAsDataURL(file);
    };

    const handleTitleChange = (e) => setTitle(e.target.value);
    const handlestartDateChange = (date) => setStartDate(date);
    const handleendDateChange = (date) => setEndDate(date);

    const handleUpload = async () => {
        setShowModal(true);

        // If user is uploading new signage but hasn't selected a file
        if (!editSignage.length && !selectedFile) {
            alert('Please select a file before uploading.');
            setShowModal(false);
            return;
        }

        // Prepare form data
        const formData = new FormData();
        if (selectedFile) {
            formData.append('file', selectedFile);
        }
        formData.append('name', title);
        formData.append('user', user.user?.Email || '');
        formData.append('contentTypeID', contentTypeID);
        formData.append('venueID', user.selectedVenue?.EntityID || '');

        // If signage is empty, there's no orderID available from signage[0].
        // You may need to supply orderID from another source or handle it differently.
        // For example, if an orderID is mandatory, ask the user for it or set it to ''.
        const currentOrderID = signage[0]?.orderID || '';

        formData.append('orderID', currentOrderID);

        const start = startDate ? startDate.toISOString() : null;
        const end = endDate ? endDate.toISOString() : null;

        // Distinguish new vs edit
        let newRecordXml;
        if (!editSignage.length) {
            // NEW Upload
            newRecordXml = {
                ContentID: 'contentID_placeholder',
                isActive: true,
                startDate: start,
                endDate: end,
                displaySeconds: displaySeconds,
                contentTypeID: contentTypeID
            };
        } else {
            // EDIT an existing signage
            newRecordXml = {
                ContentID: editSignage[0].contentID,
                isActive: true,
                startDate: start,
                endDate: end,
                displaySeconds: displaySeconds || 15,
                contentTypeID: contentTypeID || 3
            };
        }

        // Build existing records if signage array is not empty and has valid content
        let existingRecordsXml = signage
            .filter((record) => record.ContentID)
            .map((record) => ({
                ContentID: record.ContentID,
                isActive: record.IsActive,
                startDate: record.StartDate,
                endDate: record.EndDate,
                displaySeconds: record.DisplaySeconds,
                contentTypeID: record.ContentType === 'I' ? 3 : 2
            }));

        // If we do have existing records, update or add new
        if (existingRecordsXml.length > 0) {
            let recordExists = false;
            existingRecordsXml = existingRecordsXml.map((record) => {
                if (record.ContentID === newRecordXml.ContentID) {
                    recordExists = true;
                    return {
                        ...record,
                        startDate: newRecordXml.startDate,
                        endDate: newRecordXml.endDate
                    };
                }
                return record;
            });
            if (!recordExists) {
                existingRecordsXml.push(newRecordXml);
            }
        } else {
            // If no records, just push the new one
            existingRecordsXml = [newRecordXml];
        }

        // Add to formData
        formData.append('digitalSignageImageModel', JSON.stringify(newRecordXml));

        try {
            let action;
            // Decide which thunk to call: uploadImage, uploadVideo, editContent
            if (mediaType === 1 && selectedFile) {
                action = await dispatch(
                    uploadImage({
                        formData,
                        orderID: currentOrderID,
                        // If there is more than one record, pass the array;
                        // otherwise pass the single item.
                        xmlContent:
                            existingRecordsXml.length > 1
                                ? existingRecordsXml
                                : existingRecordsXml[0]
                    })
                );
            } else if (mediaType === 2 && selectedFile) {
                action = await dispatch(
                    uploadVideo({
                        formData,
                        orderID: currentOrderID,
                        xmlContent:
                            existingRecordsXml.length > 1
                                ? existingRecordsXml
                                : existingRecordsXml[0]
                    })
                );
            } else {
                // Editing without file
                action = await dispatch(
                    editContent({
                        formData,
                        orderID: currentOrderID,
                        xmlContent: existingRecordsXml
                    })
                );
            }

            // If upload was successful, reset
            if (action.type.endsWith('fulfilled')) {
                setSelectedFile(null);
                setPreviewImage(null);
                setVideoDuration(null);
                setContentTypeID(null);
                setDisplaySeconds(null);
                setMediaType(null);
                setTitle('');
                setStartDate(null);
                setEndDate(null);
                setHeight(null);
                setWidth(null);
                navigate('/digital-signage');
            } else {
                console.error('Error uploading signage:', action);
            }
        } catch (error) {
            console.error('Failed to upload signage:', error);
        } finally {
            setShowModal(false);
        }
    };

    // Form validation
    const isFormValid = !editSignage.length ? selectedFile && title : title;

    return (
        <Layout>
            <Card>
                <Card.Header as="h5">
                    {editSignage.length ? 'Edit Signage' : 'Upload Signage'}
                </Card.Header>
                <Card.Body>
                    <Form className="upload">
                        {/* Show file upload only if not editing existing signage */}
                        {!editSignage.length && (
                            <>
                                <Form.Group
className="mb-3"
controlId="formPreviewImage"
                                >
                                    {previewImage && (
                                        <div className="text-center">
                                            <img
alt="Selected Signage"
className="rounded"
src={previewImage}
                                            />
                                        </div>
                                    )}
                                </Form.Group>
                                <Form.Group
className="mb-3"
controlId="formFileUpload"
                                >
                                    <Form.Control
                                        accept=".png, .jpg, .jpeg, .gif, .mp4, .webm, .ogg, .mpeg, .mov"
                                        name="file"
                                        onChange={handleFileSelect}
                                        type="file"
                                    />
                                </Form.Group>
                            </>
                        )}

                        <Form.Group
className="mb-3"
controlId="formBasicInput"
                        >
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                disabled={!!editSignage.length}
                                onChange={handleTitleChange}
                                placeholder="Enter Title"
                                type="text"
                                value={title}
                            />
                        </Form.Group>

                        <Form.Group
className="mb-3"
controlId="formBasicInput"
                        >
                            <Form.Label>Date Range</Form.Label>
                            <Button className="d-flex rounded mb-2 w-100">
                                <FontAwesomeIcon
                                    className="account-settings"
                                    icon={faCalendar}
                                    onClick={() => navigate('/digital-signage')}
                                />
                                <DatePicker
                                    className="btn btn-primary w-50"
                                    onChange={handlestartDateChange}
                                    placeholderText="Start"
                                    selected={
                                        startDate && startDate.getFullYear() === 1753
                                            ? null
                                            : startDate
                                    }
                                />
                            </Button>

                            <Button className="d-flex rounded w-100">
                                <FontAwesomeIcon
                                    className="account-settings"
                                    icon={faCalendar}
                                    onClick={() => navigate('/digital-signage')}
                                />
                                <DatePicker
                                    className="btn btn-primary"
                                    onChange={handleendDateChange}
                                    placeholderText="End"
                                    selected={
                                        endDate && endDate.getFullYear() === 9999 ? null : endDate
                                    }
                                />
                            </Button>
                        </Form.Group>

                        <Button
disabled={!isFormValid}
onClick={handleUpload}
                        >
                            {editSignage.length ? 'Save' : 'Upload'}
                        </Button>
                    </Form>
                </Card.Body>
            </Card>

            {/* Modal for upload spinner */}
            <Modal
backdrop="static"
centered
keyboard={false}
show={showModal}
            >
                <Modal.Body className="text-center">
                    <Spinner
animation="border"
role="status"
                    >
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    <p className="mt-3">File uploading. Please wait...</p>
                </Modal.Body>
            </Modal>
        </Layout>
    );
};

export default UploadSignage;
